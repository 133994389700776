import React from "react"

import Layout from "../../components/Layout"
import Grid from "../../components/Grid"
import { Link } from "gatsby"
import css from "../setup.module.scss"
import fontType from "../../helpers/fontType.module.scss"

import TRACKINGCODE_EMAIL from "../../assets/images/setup/setup-trackcode-email.png"
import YAHOO_1 from "../../assets/images/setup/yahoo-1.png"
import YAHOO_2 from "../../assets/images/setup/yahoo-2.png"
import YAHOO_3 from "../../assets/images/setup/yahoo-3.png"
import YAHOO_4 from "../../assets/images/setup/yahoo-4.png"
import YAHOO_5 from "../../assets/images/setup/yahoo-5.png"
import YAHOO_6 from "../../assets/images/setup/yahoo-6.png"
import YAHOO_7 from "../../assets/images/setup/yahoo-7.png"
import YAHOO_8 from "../../assets/images/setup/yahoo-8.png"
import YAHOO_9 from "../../assets/images/setup/yahoo-9.png"

const metadata = {
  title: "Connect Yahoo to ReferralCandy",
}

const YahooSetup = (props) => {
  return (
    <Layout metadata={metadata} pathname={props.location.pathname}>
      <Grid className={css.container} centeredClassName={css.centered}>
        <h1 className={css.center}>Set Up ReferralCandy for Yahoo</h1>
        <p className={fontType.b1}>
          Step 1: Setting up your ReferralCandy Account
        </p>
        <ol type="1">
          <li>
            Head over to the{" "}
            <a href="https://my.referralcandy.com/signup#yahoo">sign up page</a>{" "}
            to register for a new ReferralCandy account.
          </li>
          <li>
            Go through the account setup wizard. At the integration step, note
            the tracking code and email address for your referral program. You
            will need them for the next two parts.
          </li>
          <br />
          <br />
          <img src={TRACKINGCODE_EMAIL} alt="Tracking Code Email" />
          <li>Complete the rest of the setup wizard.</li>
        </ol>
        <p className={fontType.b1}>
          Step 2: Adding the tracking code to your Yahoo Small Business store
        </p>
        <ol type="1">
          <li>
            Log into your{" "}
            <a href="https://smallbusiness.yahoo.com/">
              Yahoo Small Business Store Manager
            </a>
          </li>
          <li>
            In the Store Manager, click "Checkout & Registration Manager".
          </li>
          <br />
          <br />
          <img src={YAHOO_1} alt="Yahoo 1" />

          <li>
            In "Checkout & Registration Manager", click "Page Configuration".
          </li>
          <br />
          <br />
          <img src={YAHOO_2} alt="Yahoo 2" />
          <li>
            In "Page Configuration", click the "Order Confirmation" link in the
            middle navigation bar.
          </li>
          <li>
            In "Order Confirmation", scroll down to the "Advanced Settings"
            section.
          </li>
          <li>
            Copy and paste the ReferralCandy tracking code from Step 2 into the
            "HTML Head Section" text input box.
          </li>
          <li>Click the "Save" button below to save the changes.</li>
          <br />
          <br />
          <img src={YAHOO_3} alt="Yahoo 3" />
          <li>
            Go back to the Store Manager and click "Publish Order Settings".
          </li>
          <br />
          <br />
          <img src={YAHOO_4} alt="Yahoo 4" />
          <li>
            In the "Publish Order Settings" page, click the "Publish" button
            below to publish the changes to your site.
          </li>
          <br />
          <br />
          <img src={YAHOO_5} alt="Yahoo 5" />
        </ol>
        <p className={fontType.b1}>
          Step 3: Sending invoice emails to ReferralCandy
        </p>
        <ol type="1">
          <li>
            Make sure you have "Order Confirmation Automated Emails" set up
            before proceeding with the integration. You can find out more about
            this{" "}
            <a href="http://help.yahoo.com/l/us/yahoo/smallbusiness/store/order/order-10.html">
              here
            </a>
            .
          </li>
          <li>In the Store Manager, click "Order Emails".</li>
          <br />
          <br />
          <img src={YAHOO_6} alt="Yahoo 6" />
          <li>
            In the "Automatic Order Processing" section, copy and paste the
            ReferralCandy email address from Step 2 into the "Email To:" input
            box.
          </li>
          <li>Click "Done" in the top right corner to save your changes.</li>
          <br />
          <br />
          <img src={YAHOO_7} alt="Yahoo 7" />
          <li>
            Go back to the Store Manager and click "Publish Order Settings".
          </li>
          <br />
          <br />
          <img src={YAHOO_8} alt="Yahoo 8" />
          <li>
            In the "Publish Order Settings" page, click the "Publish" button
            below to publish the changes to your site.
          </li>
          <br />
          <br />
          <img src={YAHOO_9} alt="Yahoo 9" />
        </ol>
        <h3>
          Using a different platform? <Link to="/setup">View all guides</Link>
        </h3>
      </Grid>
    </Layout>
  )
}

export default YahooSetup
